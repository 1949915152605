var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"is-flex is-flex-wrap-wrap"},[_c('tracker-watch',{attrs:{"title":_vm.$t('Night watch'),"title-left":_vm.$t('Sunrise'),"can-clear":"","title-left-bold":""},on:{"change":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.onChange.apply(void 0, [ 'night' ].concat( argsArray ))},"clear":function($event){return _vm.onClear('night')}},model:{value:(_vm.night),callback:function ($$v) {_vm.night=$$v},expression:"night"}}),_c('tracker-watch',{attrs:{"title":_vm.$t('Morning watch'),"title-left":_vm.$t('Noon'),"can-clear":""},on:{"change":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.onChange.apply(void 0, [ 'morning' ].concat( argsArray ))},"clear":function($event){return _vm.onClear('morning')}},model:{value:(_vm.morning),callback:function ($$v) {_vm.morning=$$v},expression:"morning"}}),_c('tracker-watch',{attrs:{"title":_vm.$t('Afternoon watch'),"title-left":_vm.$t('Sunset'),"can-clear":"","title-left-bold":""},on:{"change":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.onChange.apply(void 0, [ 'afternoon' ].concat( argsArray ))},"clear":function($event){return _vm.onClear('afternoon')}},model:{value:(_vm.afternoon),callback:function ($$v) {_vm.afternoon=$$v},expression:"afternoon"}}),_c('tracker-watch',{attrs:{"title":_vm.$t('Evening watch'),"can-clear":""},on:{"change":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.onChange.apply(void 0, [ 'evening' ].concat( argsArray ))},"clear":function($event){return _vm.onClear('evening')}},model:{value:(_vm.evening),callback:function ($$v) {_vm.evening=$$v},expression:"evening"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }